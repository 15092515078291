<template>
  <div class="page-login-new">
    <div class="login-bg">
      <img src="../../../assets/login/login_bg_new1.png" alt="" />
    </div>
    <div class="body-contianer">
      <div class="body-wrap">
        <div class="title">{{ $t('login.login') }}</div>

        <div class="err-msg" v-if="errMsg">{{ errMsg }}</div>

        <div class="ipt-wrap">
          <div class="ipt-group">
            <label>{{ $t('login.mobile') }}</label>
            <el-input
              :placeholder="$t('login.mobileTip')"
              v-model="phoneNum"
              class="input"
            >
              <el-select
                v-model="area"
                slot="prepend"
                :placeholder="$t('login.selectTip')"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="`+${item.cipher}`"
                  :value="item.id"
                  >{{ item.en }}</el-option
                >
              </el-select>
            </el-input>
          </div>
          <div class="ipt-group">
            <label>{{ $t('login.password') }}</label>
            <el-input
              ref="input"
              :type="inputType"
              :placeholder="$t('login.password')"
              v-model="password"
              class="input"
            >
              <i
                :class="{ active: inputType === 'text' }"
                class="el-icon-view el-input__icon"
                slot="suffix"
                @click="changeType"
              >
              </i>
            </el-input>
          </div>

          <div class="bottom">
            <a @click.stop="handleNavForget">{{ $t('login.forgetPwd') }}</a>
          </div>
        </div>

        <div class="login-btn">
          <div class="submit">
            <el-button type="primary" round @click="handleLogin">
              {{ $t('login.login') }}
            </el-button>
          </div>
          <div class="other">
            <span>{{ $t('login.otherLogin') }}</span>
            <ul>
              <li>
                <i class="iconfont icon-wechat" @click="handleWechatLogin"></i>
              </li>
              <li>
                <g-signin-button
                  :params="{
                    client_id:
                      '571929496481-sj89mocnammt9gpcmu8afq7jafv9ftpt.apps.googleusercontent.com'
                  }"
                  @success="handleGoolgeSign"
                >
                  <i class="iconfont icon-google"></i>
                </g-signin-button>
              </li>
              <li>
                <i class="iconfont icon-apple" @click="handleAppleLogin">
                  <!--                  <vue-apple-signin-->
                  <!--                    color="white"-->
                  <!--                    :border="false"-->
                  <!--                    type="sign in"-->
                  <!--                    class="apple-sign-btn"-->
                  <!--                  />-->
                </i>
              </li>
            </ul>
            <a @click.stop="handelNavRegister">{{ $t('login.register') }}</a>
          </div>
        </div>

        <div class="bottom-wrap">
          <div class="links">
            <a href="https://ptego.com/" target="_blank">{{
              $t('login.aboutMe')
            }}</a>
            <a href="https://ptego.com/terms" target="_blank">{{
              $t('login.privacy')
            }}</a>
            <a href="https://ptego.com/terms" target="_blank">{{
              $t('login.terms')
            }}</a>
          </div>
          <div class="copyright">
            PTEGO Copyright © 2021 All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiCheckAreacode,
  apiPwdLogin,
  apiWechatCbLogin,
  apiAppleWebLogin
} from '@/api/api.js'
import { apiGoogleLogin } from '@/api/userInfo'
import axios from 'axios'

const getQueryString = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

export default {
  data() {
    return {
      areaList: [], //区域列表
      phoneNum: '',
      password: '',
      area: '',
      errMsg: '',
      inputType: 'password'
    }
  },
  created() {
    this.getAreaCode()
    // 回车按钮函数
    this.handleEnter = (event) => {
      if (event.keyCode === 13) {
        this.handleLogin()
      }
    }
    document.addEventListener('keyup', this.handleEnter)
    AppleID.auth.init({
      clientId: 'com.myfeifan.studen.client',
      scope: 'name email',
      redirectURI: `https://${window.location.host}`,
      state: 'STATE',
      usePopup: true
    })
  },
  beforeMount() {
    this.wechatCallback()
  },

  mounted() {
    this.init()
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEnter)
  },
  methods: {
    changeType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
      this.$refs.input.focus()
    },
    init() {
      if (localStorage.getItem('token')) {
        this.$router.push({
          name: 'home'
        })
      }
    },

    // 微信
    wechatCallback() {
      const code = getQueryString('code')
      const close = getQueryString('close')

      if (code) {
        this.getWechatInfo(code, close)
      }
    },
    getWechatInfo(code, close) {
      apiWechatCbLogin({
        code: code
      }).then((res) => {
        if (res.data.unionid) {
          this.$layer.msg('微信扫码成功~请先绑定手机号~')

          this.$router.push({
            name: 'bindMobile',
            query: {
              unionid: res.data.unionid
            }
          })
        } else if (res.data.access_token) {
          localStorage.setItem('token', res.data.access_token)
          axios.defaults.headers.assceetoken = res.data.access_token

          if (close) {
            window.close()
          }

          this.$layer.msg('微信扫码成功~页面跳转中...')

          setTimeout(() => {
            this.$router.push({
              name: 'home'
            })
          }, 1000)
        }
      })
    },

    // 获取区域区号【选择国家区域】
    getAreaCode() {
      apiCheckAreacode().then((res) => {
        this.areaList = res.data
        this.area = (this.areaList[0] || {}).id
        const phoneId = JSON.parse(localStorage.getItem('phoneId'))
        if (phoneId.id) {
          this.area = phoneId.id
        }
      })
    },
    handelNavRegister() {
      this.$router.push({
        name: 'register'
      })
    },
    handleNavForget() {
      this.$router.push({
        name: 'forget'
      })
    },
    handleLogin() {
      const areaData = this.areaList.find((m) => m.id === this.area)
      localStorage.setItem('phoneId', JSON.stringify(areaData))
      if (!this.phoneNum.trim() || !eval(areaData.match).test(this.phoneNum)) {
        this.errMsg = '手机号码格式错误'
        return false
      }
      apiPwdLogin({
        phone: this.phoneNum,
        country_code: areaData.cipher,
        login_pwd: this.password
      }).then(({ code, data, msg }) => {
        if (code === 200) {
          localStorage.setItem('token', data.access_token)
          axios.defaults.headers.assceetoken = data.access_token
          this.$layer.msg(
            "<div class='success-icon-big'></div><div>登录成功，跳转中...</div>"
          )
          setTimeout(() => {
            this.$router.push({
              name: 'home'
            })
          }, 1000)
        } else {
          this.errMsg = msg
        }
      })
    },
    handleWechatLogin() {
      let redurectUri = `https://${window.location.host}${
        /feifan/gi.test(window.location.host) ? '/web' : ''
      }?close=1`
      window.open(
        `https://open.weixin.qq.com/connect/qrconnect?appid=wx83f99bf7d6d02dc2&redirect_uri=${encodeURIComponent(
          redurectUri
        )}&response_type=code&scope=snsapi_login#wechat_redirect`
      )

      const timeId = setInterval(() => {
        const token = localStorage.getItem('token')
        if (token) {
          clearInterval(timeId)
          this.$layer.msg(
            "<div class='success-icon-big'></div><div>微信登录成功，跳转中...</div>"
          )

          window.location.reload()
        }
      }, 1000)
    },
    async handleAppleLogin() {
      try {
        const data = await AppleID.auth.signIn()
        console.log('===== apple id login data:  ', data)

        const res = await apiAppleWebLogin({
          apple_id_token: data.authorization.id_token
        })
        if (res.data.apple_id_token) {
          this.$layer.msg('Apple 登录成功~请先绑定手机号~')

          this.$router.push({
            name: 'bindMobile',
            query: {
              appleIdToken: res.data.apple_id_token
            }
          })
        } else if (res.data.access_token) {
          localStorage.setItem('token', res.data.access_token)
          axios.defaults.headers.assceetoken = res.data.access_token

          this.$layer.msg('Apple 登录成功~页面跳转中...')

          setTimeout(() => {
            this.$router.push({
              name: 'home'
            })
          }, 1000)
        }
      } catch (error) {
        //handle error.
        console.log('===== apple id login fail:  ', error)
        this.$layer.msg('Apple ID 登录失败，请稍后再试')
      }
    },

    async handleGoolgeSign(googleUser) {
      const userInfo = googleUser.getBasicProfile()
      const res = await apiGoogleLogin({
        google_id: userInfo.getId(),
        user_name: userInfo.getName(),
        user_email: userInfo.getEmail(),
        user_logo: userInfo.getImageUrl()
      })
      if (res.data.access_token) {
        localStorage.setItem('token', res.data.access_token)
        axios.defaults.headers.assceetoken = res.data.access_token

        this.$layer.msg('Google 登录成功~页面跳转中...')

        setTimeout(() => {
          this.$router.push({
            name: 'home'
          })
        }, 1000)
      } else {
        this.$layer.msg('Google 登录成功~请先绑定手机号~')

        this.$router.push({
          name: 'bindMobile',
          query: {
            googleId: userInfo.getId()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-login-new {
  display: flex;
  color: #3a3c4d;
  .el-icon-view {
    &.active {
      color: #3b86ff;
    }
  }

  .login-bg {
    > img {
      height: 100vh;
    }

    @media (max-width: 500px) {
      width: 38px;
      > img {
        visibility: hidden;
      }
    }
  }

  .body-contianer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -9%;

    @media (max-width: 500px) {
      width: 80%;
    }

    .body-wrap {
      width: 380px;
      padding-top: 100px;
      .tip {
        background-color: #ebebf2;
        border-radius: 5px;
        padding: 10px 14px;
        font-size: 14px;
        color: #7c8084;
      }
      .title {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
        padding-bottom: 14px;
      }
      .err-msg {
        padding: 6px 12px;
        color: #ff606d;
        background-color: #ffcfd3;
        border-radius: 5px;
      }
      .ipt-wrap {
        .ipt-group {
          padding-top: 20px;
          > label {
            padding-bottom: 10px;
            font-size: 14px;
          }
          .input {
            margin-top: 10px;
            ::v-deep .el-select {
              width: 74px;
            }
            ::v-deep .el-input-group__prepend {
              background-color: #fff;
            }
            ::v-deep .el-input__inner:hover {
              border-color: #3b86ff;
            }
            ::v-deep .el-input__inner:focus {
              border-color: #3b86ff;
              box-shadow: 0px 0px 0px 2px #bae7ff;
            }
          }
        }
      }
      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        > a {
          font-size: 14px;
          text-decoration: none;
          color: #3b86ff;
          cursor: pointer;
          &:after,
          &:focus {
            outline: none;
          }
        }
      }
      .login-btn {
        margin-top: 60px;
        .submit {
          ::v-deep .el-button {
            width: 100%;
            background-color: #3b86ff;
            border-color: #3b86ff;
          }
        }
        .other {
          display: flex;
          align-items: center;
          margin-top: 20px;
          > span {
            margin-right: 30px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
          }
          > ul {
            flex: 1;
            display: flex;
            align-items: center;
            > li {
              margin: 0 10px;
              i {
                cursor: pointer;
                font-size: 30px;
                color: #cfd1e2;
                position: relative;
                overflow: hidden;
                width: 30px;
                display: inline-block;
                &:hover {
                  color: #3b86ff;
                }
                .apple-sign-btn {
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 30px;
                  opacity: 0;
                }
              }
            }
          }
          > a {
            font-size: 14px;
            text-decoration: none;
            color: #3b86ff;
            cursor: pointer;
            &:after,
            &:focus {
              outline: none;
            }
          }
        }
      }
      .bottom-wrap {
        margin-top: 200px;
        text-align: center;
        .links {
          > a {
            width: 33%;
            display: inline-block;
            font-size: 14px;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.45);
            &:after,
            &:focus {
              outline: none;
            }
          }
        }
        .copyright {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
